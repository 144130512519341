<template>
  <div v-if="dataCBT.mata_pelajaran" class="briefExam">
    <h2 style="text-align: center;">Subject : {{ dataCBT.mata_pelajaran.nama }}</h2>
    <div class="contentBriefExam">
      <a-row :gutter="16">
        <a-col style="text-align: center" :span="7">
          <h3 style="margin-bottom: 3%!important;">Total Question</h3>
          <div>{{ soalPG + soalEssay }} ({{ soalPG }} multiple choice + {{ soalEssay }} essay)</div>
        </a-col>
        <a-col style="text-align: center" :span="5">
          <h3 style="margin-bottom: 3%!important;">Duration</h3>
          <div>{{ dataCBT.durasi }} minutes</div>
        </a-col>
        <a-col style="text-align: center" :span="6">
          <h3 style="margin-bottom: 3%!important;">Type</h3>
          <div>{{ dataCBT.tipe }}</div>
        </a-col>
        <a-col style="text-align: center" :span="6">
          <h3 style="margin-bottom: 3%!important;">Class</h3>
          <div>{{ dataCBT.kelas || '-' }}</div>
        </a-col>
      </a-row>
    </div>
    <div class="contentBriefExam" style="display: block; margin: 5% auto; width: 50%">
      <h3 style="text-align: center;">INSTRUCTIONS</h3><br />
      <div v-html="dataCBT.instruksi ? dataCBT.instruksi : ''" />
    </div>
    <a-divider />
    <div class="contentBriefExam">
      <div style="margin-bottom: 10%">
        Computer-based test is a test conducted by using a computer. All exam aspects (question and choices / essay's
        answer) are presented on the screen. Question will be showed number by number. The question is on the left side,
        while the answer is on the right side of the screen.
      </div>
      <a-button @click.prevent="startExam" type="primary" size="large"
        :disabled="dataCBT.cbt_attendances[0].status_attendance === 'done'" block :loading="loadingStart">START</a-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingStart: false,
      dataCBT: {},
      soalPG: 0,
      soalEssay: 0,
    }
  },
  methods: {
    startExam() {
      this.loadingStart = true
      setTimeout(() => {
        this.$store.dispatch('cbt/UPDATE_START_TIME', {
          idCbtAttendance: this.dataCBT.cbt_attendances[0].id,
        })
          .then(isError => {
            if (!isError) {
              this.$emit('startOnProgress')
              this.$store.dispatch('cbt/FETCH_STUDENT_CBT_QUESTION', {
                idCBT: this.$route.params.id,
                idMurid: this.user.id,
              })
              this.loadingStart = false
              this.$emit('startExam')
            } else {
              this.loadingStart = false
              this.$notification.error({
                message: 'Sorry.',
                description: 'Internal Server Error. Please check your connection',
              })
            }
          })
      }, 2000)
    },
  },
  created() {
    this.$store.dispatch('cbt/FETCH_BRIEF_PAGE_STUDENT_CBT_EXAM', {
      idCBT: this.$route.params.id,
      idMurid: this.user.id,
    })
      .then(({ dataCBT, countPG, countEssay }) => {
        this.dataCBT = dataCBT
        this.soalPG = countPG
        this.soalEssay = countEssay
      })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>
<style lang="scss">
.briefExam {
  .contentBriefExam {
    display: block;
    margin: 5% auto;
    width: 75%;

    li {
      padding-left: 4%;
    }

    .ant-btn-primary[disabled] {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
    }
  }
}
</style>
