<template>
  <div>
    <LoadingState v-if="isLoading" />
    <a-row :gutter="16" v-else>
      <a-col :sm="24" :md="6" v-sticky="{ zIndex: 99, stickyTop: 10, disabled: isDisabledSticky }">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>

        </div>
      </a-col>
      <a-col :sm="24" :md="18">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-md-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-1" style="display: flex; justify-content: center; align-items: center;
          flex-direction: column;">
            <template v-if="menuActive === 1">
              <div v-if="isFetching" class="w-100 text-center my-5">
                <a-spin>
                  <a-icon slot="indicator" type="loading" style="font-size: 5rem; color: var(--kit-color-primary)" spin />
                </a-spin>
              </div>
              <div class="step">
                <a-steps :current="current" labelPlacement="vertical" size="small"
                  :direction="settings.isMobileView ? 'vertical' : 'horizontal'">
                  <a-step v-for=" item in steps" :key="item.title" :title="item.title" />
                </a-steps>
              </div>
              <div v-if="current === 0">
                <a-row :gutter="[32, 16]">
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="Name">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="E-mail">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="NISN">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :md="24" :lg="12">
                    <a-form-item label="Gender">
                      <a-radio-group class="radio-gender w-100" size="large" button-style="solid" value="Perempuan">
                        <a-radio-button value="Laki-Laki" class="w-50 text-center">Laki-Laki</a-radio-button>
                        <a-radio-button value="Perempuan" class="w-50 text-center">Perempuan</a-radio-button>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="24">
                    <a-form-item label="Address">
                      <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }" :disabled="true" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
              <div v-if="current === 1" class="ujian">
                <h1>Ujian CBT akan segera dilaksanakan</h1>
                <h3>Pelaksanaan ujian CBT akan dilangsungkan secara serentak secara online pada</h3>
                <div class="time">
                  <div class="type">
                    <div class="number">
                      <h2>00</h2>
                    </div>
                    <div class="text">Hari</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>18</h2>
                    </div>
                    <div class="text">Jam</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>34</h2>
                    </div>
                    <div class="text">Menit</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>24</h2>
                    </div>
                    <div class="text">Detik</div>
                  </div>
                </div>
                <h3>Jumat, 22 September 2023 08:00 WIB</h3>
              </div>
              <div v-if="current === 2" class="wawancara">
                <h1>Wawancara dengan Orangtua</h1>
                <div class="info-wawancara">
                  <div style="display: flex; align-items: baseline; margin-top: 10px;"><a-icon type="calendar"
                      style="margin-right: 10px;" />
                    <p>Jumat, 22 September 2023 </p>
                  </div>
                  <div style="display: flex; align-items: baseline; margin-top: 10px;">
                    <a-icon type="environment" style="margin-right: 10px;" />
                    <p><strong>SMP AL Bayan</strong><br>
                      Jl. Bazoka Raya, Joglo, Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta, Joglo,
                      Kembangan, West Jakarta City, Jakarta 11640 </p>
                  </div>
                </div>
              </div>
              <div v-if="current === 3" class="ujian">
                <h1>Pengumuman Hasil Seleksi Sebentar Lagi!</h1>
                <h3 style="width: 500px;">Hasil seleksi calon peserta didik baru SMP Al Bayan tahun ajaran 2023/2024 akan
                  segera diumumkan pada :
                </h3>
                <div class="time">
                  <div class="type">
                    <div class="number">
                      <h2>00</h2>
                    </div>
                    <div class="text">Hari</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>18</h2>
                    </div>
                    <div class="text">Jam</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>34</h2>
                    </div>
                    <div class="text">Menit</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>24</h2>
                    </div>
                    <div class="text">Detik</div>
                  </div>
                </div>
                <h3>Jumat, 22 September 2023 08:00 WIB</h3>
              </div>
              <div v-if="current === 4" class="ujian">
                <h1>Pendaftaran Ulang Akan Segera ditutup!</h1>
                <h3 style="width: 500px;">Pendaftaran ulang calon siswa SMP Al Bayan tahun ajaran 2023/2024 akan segera
                  ditutup dalam :
                </h3>
                <div class="time">
                  <div class="type">
                    <div class="number">
                      <h2>00</h2>
                    </div>
                    <div class="text">Hari</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>18</h2>
                    </div>
                    <div class="text">Jam</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>34</h2>
                    </div>
                    <div class="text">Menit</div>
                  </div>
                  <h2 style="margin-left: 10px; margin-right: 10px;font-size: 7vw;">:</h2>
                  <div class="type">
                    <div class="number">
                      <h2>24</h2>
                    </div>
                    <div class="text">Detik</div>
                  </div>
                </div>
                <h3>Jumat, 22 September 2023 08:00 WIB</h3>
              </div>
            </template>
            <template v-if="menuActive === 2">
              <div class="information">
                <img src="@/assets/ge.webp" alt="">
                <div class="general">
                  <h1>Mari Belajar Bersama Sekolah Berpengalaman</h1>
                  <p>Sebagai salah satu lembaga pendidikan formal yang bertujuan untuk mengimplementasikan nilai-nilai
                    Al-Quran dalam setiap aspek pendidikannya dan selalu berupaya untuk menjadi solusi dari permasalahan
                    mutu pendidikan yang masih relatif rendah – khususnya pendidikan Islam.
                  </p>
                </div>
                <div class="visimisi">
                  <h1>Visi & Misi Sekolah</h1>
                  <p>VISI <br>
                  <ul style="list-style-type:circle">
                    <li>Membentuk Generasi Qurani yang Cerdas, Kreatif dan Berwawasan Global.</li>
                  </ul>

                  MISI <br>
                  <ul style="list-style-type:circle">
                    <li>Mengamalkan nilai-nilai keislaman dalam pelaksanaan kegiatan pendidikan. </li>
                    <li>Membangun suasana belajar yang kondusif.</li>
                    <li>Menghasilkan peserta didik yang unggul, inovatif dan berakhlaqul Karimah</li>
                  </ul>
                  </p>
                </div>
              </div>
            </template>
            <template v-if="menuActive === 3">
              <div>
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th>Jenis Keuangan</th>
                      <th>Biaya</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in dataTable" :key="item.key">
                      <td>{{ item.jenisKeuangan }}</td>
                      <td style="text-align: right;">{{ item.biaya }}</td>
                    </tr>
                    <tr>
                      <td colspan="1" style="text-align: center;color: #357C2C;" class="totalBiaya">
                        <strong>JUMLAH</strong>
                      </td>
                      <td style="text-align: right;color: #357C2C;" class="totalBiaya"><strong>Rp. 11.500.000,-</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-if="menuActive === 6">
              <div>
                <a-row :gutter="[32, 16]">
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="Name">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="E-mail">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <a-form-item label="NISN">
                      <a-input size="large" :disabled="true" />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :md="24" :lg="12">
                    <a-form-item label="Gender">
                      <a-radio-group class="radio-gender w-100" size="large" button-style="solid" value="Perempuan">
                        <a-radio-button value="Laki-Laki" class="w-50 text-center">Laki-Laki</a-radio-button>
                        <a-radio-button value="Perempuan" class="w-50 text-center">Perempuan</a-radio-button>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="24" :lg="24">
                    <a-form-item label="Address">
                      <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }" :disabled="true" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </template>
            <template v-if="menuActive === 4">
              <div class="alurPelaksanaan">
                <AlurPelaksanaan />
              </div>
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import VueSticky from 'vue-sticky'
import config from '@/config'
import { mapState } from 'vuex'

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    // VueHtml2pdf: () => import('vue-html2pdf'),
    LoadingState: () => import('@/components/app/LoadingState'),
    Menu: () => import('@/components/app/MenuV2'),
    AlurPelaksanaan: () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan'),
  },
  data() {
    return {
      config,
      menus: [
        {
          id: 1,
          key: 1,
          menu: 'Dashboard',
        },
        {
          id: 2,
          menu: 'Information',
          sub: true,
          children: [
            { key: 2, menu: 'General Information', value: 'Ganjil' },
            { key: 3, menu: 'Cost Information', value: 'Genap' },
            { key: 4, menu: 'Registration Schedule', value: 'Genap' },
            { key: 5, menu: 'CBT Procedures', value: 'Genap' },
          ],
        },
        {
          id: 3,
          key: 6,
          menu: 'My Account',
        }, {
          id: 4,
          key: 7,
          menu: 'Payment',
        },
      ],
      menuActive: 1,
      configSchool: '',
      generalReport: {},
      loadingExport: false,
      chartData: {
        labels: [],
        series: [],
        fullWidth: true,
      },
      pdfOptions: {
        margin: [10, 10, 10, 10],
        filename: 'File',
        image: {
          type: 'jpeg',
          quality: 0.50,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      reportType: 'mid',
      reportText: {
        mid: 'MID',
        final: 'FINAL',
      },
      disableSelectType: false,
      current: 1,
      isFetching: false,
      steps: [
        {
          title: 'Isi Formulir',
          content: 'First-content',
        },
        {
          title: 'Ujian CBT',
          content: 'Second-content',
        },
        {
          title: 'Wawancara',
          content: 'Last-content',
        },
        {
          title: 'Pengumuman',
          content: 'Last-content',
        },
        {
          title: 'Daftar Ulang',
          content: 'Last-content',
        },
      ],
      dataTable: [
        {
          key: '1',
          no: '1',
          jenisKeuangan: 'Uang Pembangunan',
          biaya: 'Rp. 5.000.000',
        },
        {
          key: '2',
          no: '2',
          jenisKeuangan: 'Pengembangan sarana dan prasarana',
          biaya: 'Rp. 2.000.000',
        },
        {
          key: '3',
          no: '3',
          jenisKeuangan: 'Kegiatan akademik',
          biaya: 'Rp. 1.000.000',
        },
        {
          key: '4',
          no: '4',
          jenisKeuangan: 'SPP Bulan Juli 2023',
          biaya: 'Rp. 750.000',
        },
        {
          key: '5',
          no: '5',
          jenisKeuangan: 'Kegiatan Kesiswaan Selama Setahun',
          biaya: 'Rp. 1.500.000',
        },
        {
          key: '6',
          no: '6',
          jenisKeuangan: 'Seragam Sekolah (1 Stel Yayasan, 1 Stel Batik, 1 Stel Olahraga, Jas Almamater, Badge dan Topi)',
          biaya: 'Rp. 1.200.000',
        },
        {
          key: '7',
          no: '7',
          jenisKeuangan: 'Sarana Kebutuhan Siswa (Asuransi, Buku Kendali Siswa, Kartu Pelajar, Map Raport, Map Ijazah)',
          biaya: 'Rp. 500.000',
        },
      ],
    }
  },
  methods: {
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  async mounted() {
    try {
    } catch (err) {
    }
  },
  computed: {
    ...mapState(['settings']),
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = (el.children || []).filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
<style scoped>
:global(.ant-steps .ant-steps-item-process .ant-steps-item-icon) {
  background-color: #357C2C;
  border: none
}

:global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  color: #fff;
  background-color: #357C2C;
  border-color: #357C2C;
}

:global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  background-color: #357C2C;
  border-color: #357C2C;
  /* background-color: #1890ff; */
}

:global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before) {
  background-color: #357C2C;
}

:global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover) {
  background-color: #268419;
  border-color: #268419;
}

:global(.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after) {
  background-color: #357C2C;
}

:global(.ant-steps-item-finish .ant-steps-item-icon) {
  border-color: #357C2C;
}

:global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
  color: #357C2C;
}

.ant-radio-button-wrapper:hover {
  color: #357C2C;
  border: 1px solid #78bc6f;
}

.step {
  width: 100%;
  align-items: center;
  /* background-color: aqua; */
}

.ujian {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ujian h1 {
  color: #357C2C;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  /* 60px */
}

.ujian h3 {
  padding-top: 30px;
  width: 80%;
  text-align: center;
  color: #9B9B9B;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-bottom: 20px;
  /* 28.8px */
}

.time {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.type {
  text-align: center;
  margin-right: 10px;
}

.text {
  font-size: 1.6vw;
}

.number h2 {
  color: #041930;
  font-family: Inter;
  font-size: 7vw;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.wawancara {
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wawancara h1 {
  color: #357C2C;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  /* 60px */
}

.info-wawancara {
  width: 400px;
  text-align: justify;
}

.info-wawancara div p {
  padding-bottom: 10px;
  width: 400px;
  color: #00373E;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.information {
  width: 100%;
  height: 100%;
  /* background-color: #78bc6f; */
}

.information img {
  width: 100%;
  height: 100%;
}

.information h1 {
  color: #357C2C;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.custom-table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  /* Menggabungkan garis tepi sel */
  width: 100%;
  height: auto;
  /* Lebar tabel */
  /* margin-top: 50px; */
  /* Jarak atas tabel dari elemen sebelumnya */
}

.custom-table th {
  background-color: #E6F4F1;
  /* Warna latar belakang header */
  /* Garis tepi header */
  padding: 16px 16px 17px 16px;
  align-items: center;
  gap: 16px;
  /* Ruang dalam header */
  color: #00373E;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

.custom-table td {
  border-bottom: 1px solid #F0F0F0;
  /* Garis tepi sel data */
  padding: 16.5px 16px 17.5px 16px;
  text-align: left;
  /* Ruang dalam sel data */
  color: #00373E;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

.totalBiaya {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.3px;
}

.section3 {
  width: 100%;
  /* height: 200vh; */
  /* background-color: aqua; */
  margin-bottom: 80px;
  display: flex;
  position: relative;
}

.alurPelaksanaan {
  width: 100%;
  /* background-color: red; */
}

@media screen and (max-width: 770px) {}
</style>
