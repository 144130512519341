<template>
  <div id="cbtExamBox">
    <ExamCBT v-if="isOnProgress" :isTeacher="isTeacher" />
    <BriefExamCBT @startOnProgress="startOnProgress" v-else />
  </div>
</template>
<script>
import BriefExamCBT from '@/components/app/BriefExamCBT'
import ExamCBT from '@/components/app/ExamCBT'
export default {
  data() {
    return {
      examIsStarted: false,
      isOnExam: JSON.parse(localStorage.isOnExam),
      isOnProgress: false,
      isTeacher: false,
    }
  },
  components: {
    BriefExamCBT,
    ExamCBT,
  },
  methods: {
    startOnProgress() {
      this.isOnProgress = true
    },
  },
  created() {
    this.$store.dispatch('cbt/CHECK_CBT_ATTENDACE', {
      idCbt: this.$route.params.id,
      idMurid: this.user.id,
    })
      .then(data => {
        this.isOnProgress = data.isOnProgress
      })
  },
  computed: {
    isOnExamState() {
      return this.$store.state.cbt.isOnExam
    },
    user() {
      return this.$store.state.user.user
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('isOnExam', false)
    this.$store.commit('SET_STATE', {
      isOnExam: false,
    })
    next()
  },
}
</script>
<style lang="scss">
#cbtExamBox {
  margin-top: 4%;
  width: 100%;
  min-height: 80vh;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #0419301A;
  padding: 5% 3%;
}
</style>
